body {
  height: 100%;
  width: 100%;
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Roboto', 'Segoe UI',
    'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

html,
#root {
  height: 100%;
  width: 100%;
  box-sizing: border-box;
}

@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: normal;
  src: url('./assets/fonts/Roboto-Regular.ttf');
}

button {
  user-select: none;
  -moz-user-select: none;
  -webkit-user-select: none;
}

button:focus {
  outline: 1px dotted;
  outline: 0px auto -webkit-focus-ring-color;
}

.contain-avatar > .MuiAvatar-img {
  object-fit: contain !important;
  border: 1px solid #d1d1d1 !important;
}

.MuiAutocomplete-endAdornment .MuiIconButton-root{
    padding: 0;
}
